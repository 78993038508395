.growth-metrics {
  display: block;
  margin-bottom: 1rem;

  > .timespan-selection-info-message {
    margin: 2rem 0;
  }

  > .error-message {
    margin: 2rem 0;
  }
  .option-to-content {
    display: block;
    width: 100%;
    height: 100%;
    font-family: monospace;
    font-size: 14px;
    line-height: 1.6rem;
    resize: none;
    box-sizing: border-box;
    outline: none;
    color: rgb(0, 0, 0);
    border: 1px solid rgb(51, 51, 51);
    background-color: rgb(255, 255, 255);
    overflow: auto;
  }
  > .timespan-selection-container {
    margin: 1rem 0 0 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    > .wpp-typography {
      margin-bottom: 0.5rem;
    }
    > .selections-buttons {
      margin-bottom: 2rem;
    }
  }
  > .rendering-charts-section {
    position: relative;
    > .info-message {
      position: absolute;
      margin: 0 auto;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      > .wpp-typography {
        position: absolute;
        margin: 0 auto;
        left: 0;
        right: 0;
        top: 6rem;
        background: var(--wpp-grey-color-400);
        padding: 1rem;
        z-index: 1;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    > .charts-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;
      flex-wrap: wrap;

      &.disable {
        opacity: 0.2;
        pointer-events: none;
      }
      > .single-chart-container {
        position: relative;
        overflow: hidden;
        > .chart-title {
          margin-bottom: 0;
        }
        flex: calc(50% - 5rem);
        max-width: calc(50% - 4rem);
        padding: 2rem;
        > .chart {
          height: 350px;
        }
      }
    }
  }
  > .export-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}
