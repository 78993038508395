.datasets-form {
  > .content {
    > section {
      .row-1 {
        .col-1:nth-of-type(2) {
          width: 40%;
        }
        .col-1.checkbox {
          width: 2rem;
        }

        .col-1.inline {
          display: flex;

          > * {
            margin: auto 0;
            flex: 1;

            &.label {
              max-width: 6rem;
            }
          }
        }

        &.dataset {
          &-title,
          &-item {
            &-content > .col-1 {
              &:nth-of-type(1) {
                width: 40%;
              }
              &:nth-of-type(2) {
                width: 30%;
              }
              &:nth-of-type(3) {
                width: 10%;

                .wpp-single-select {
                  min-width: 10%;
                }
              }
              &:nth-of-type(4) {
                width: 20%;
              }
            }
          }
        }
      }
    }
  }
}
