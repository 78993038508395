body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  height: 100%;
  margin: 0;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 1280px;
}

.Container-wpp .App {
  display: flex;
  flex-direction: column;
  height: calc(100vh - (var(--wpp-os-header-height) + 70px));
  min-width: 1280px;
  text-align: center;
}

.Container-dsw .App {
  display: flex;
  flex-direction: column;
  min-height: 720px;
  min-width: 1280px;
  text-align: center;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.spinner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-top: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* make keyframes that tell the start state and the end state of our object */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0.1;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  from {
    opacity: 0.1;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0.1;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn ease-in 1;
  animation-fill-mode: forwards;
  animation-duration: 1s;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

.pulse {
  animation: pulse 2s infinite;
}

@keyframes new {
  0% {
    color: var(--tab-text-color);
  }
  80% {
    color: var(--wpp-brand-color);
  }
  100% {
    color: var(--tab-text-color);
  }
}

.new {
  animation: new 2s 10;
}
