:root {
  --ag-icon-font-family: 'agGridMaterial';
}

.segmentContainer {
  margin-top: 0.5rem;
  margin-top: 1rem;
  border: 1px solid var(--wpp-grey-color-300);
  border-radius: 0.25rem;
  padding: 0.75rem;
  height: 92%;
  flex-direction: row;

  .header {
    margin-bottom: 14px;
    height: 80px;

    .heading {
      width: 100%;
      text-align: left;
    }
  }

  .filter {
    display: flex;
    flex-direction: row;

    .wpp-single-select.filterBy::part(single-select-input) {
      border-radius: 5px 0px 0px 5px;
    }

    .filterBy {
      width: 30%;
    }

    .clearLabel {
      width: 145px;
      text-align: center;
    }

    .clear::part(button) {
      &:hover {
        background-color: var(--wpp-grey-color-200);
      }
    }

    .wpp-multiple-select.filterValues::part(multiple-select-input) {
      border-radius: 0px 5px 5px 0px;
      border-left: none;
    }

    .filterValues {
      width: 70%;
    }

    .wpp-single-select::part(placeholder) {
      text-align: left;
    }
  }

  .segments {
    flex-grow: 1;
    height: 90%;
    // overflow: auto;

    .segments-list {
      display: flex;
      width: 100%;
      flex-direction: column;

      div[data-tippy-root] {
        width: 100%;
        margin-left: -5px !important;

        .ag-center-cols-viewport {
          width: 99.8%;
        }
        .ag-center-cols-container {
          width: 99%;
          height: 100%;
        }

        .container {
          padding: 1px;
          border: 1px solid var(--wpp-grey-color-300);
        }

        .ag-header {
          background-color: var(--wpp-primary-color-200);
        }

        .ag-header-row {
          color: var(--wpp-primary-color-600);
        }

        #agGridSalesforceDetails {
          width: 99.8%;
        }
      }

      .ag-grid-asd {
        width: 100%;
        height: 100%;
      }

      .ag-center-cols-viewport {
        width: 99.8%;
      }
      .ag-center-cols-container {
        width: 99.2%;
        height: 100%;
      }

      .ag-body {
        overflow: auto;
      }

      .grid-button {
        display: flex;
        margin-left: -10px;

        .wpp-icon-button {
          &::part(wrapper) {
            --wpp-button-secondary-border-color: var(--wpp-grey-color-400);
          }
          &::part(inner) {
            --button-secondary-icon-color: var(--wpp-grey-color-400);
          }

          &:hover {
            &::part(wrapper) {
              --wpp-button-secondary-border-color: var(--wpp-brand-color);
            }
            &::part(inner) {
              --button-secondary-icon-color: var(--wpp-brand-color);
            }
          }
        }
      }

      .ag-root-wrapper-body {
        font-size: 0.75rem;

        .ag-header {
          border: none;
        }

        .ag-header-cell-comp-wrapper,
        .ag-cell-label-container {
          height: auto;
        }

        .ag-row {
          border: var(--ag-row-border-style) var(--ag-row-border-color)
            var(--ag-row-border-width);
        }

        .ag-cell {
          font-size: 0.75rem;
        }

        .ag-sorting {
          margin-top: 3px;
        }

        .viewSalesforceAccount {
          margin-left: -10px;
          margin-top: 10px;
        }
      }

      .salesforce-details-container {
        display: flex;
        width: 100%;
        height: auto;
        flex-direction: column;
      }
    }
  }
}

.exportConfirmationModal {
  div[slot='header'] {
    padding: 10px;
  }
  div[slot='body'] {
    padding: 10px;
  }
  div[slot='actions'] {
    display: flex;
    justify-content: center;

    .wpp-button {
      margin: 0px 5px;
    }
  }
}

.wpp-toast-container {
  position: absolute;
  top: 165px;
  right: 55px;
  bottom: unset;
  left: unset;
}
