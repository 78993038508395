.content-wrapper.data {
  margin: 0;
  padding-top: 0;
  height: 100%;

  .datasets-and-catalogs {
    height: 100%;
    display: flex;
    flex-direction: column;

    > section.filters {
      margin: 1rem 0;

      > .row-1 > .col-1 {
        &:nth-of-type(1) {
          display: flex;
          flex-direction: column;
          width: 8rem;

          .label {
            display: flex;
            flex-direction: column;
            text-align: left;
            margin: auto 0;
          }
        }
        &:nth-of-type(2) {
          min-width: 20rem;
          max-width: 20rem;

          .wpp-single-select::part(placeholder) {
            text-align: left;
          }
        }
        &:nth-of-type(3) {
          margin-left: 1rem;
        }
      }
    }

    > .row-1.expander {
      border-top: 2px solid var(--wpp-grey-color-300);
      margin: 0;

      &.collapsed {
        border-top: 0 solid transparent;
      }

      .col-1 {
        width: 100%;

        .wpp-action-button {
          .wpp-icon-chevron {
            color: var(--wpp-brand-color);
          }
        }
      }
    }

    > div.page {
      overflow: hidden;
      display: flex;
      flex: 1;
      flex-direction: column;
      height: 100%;

      .ag-root-wrapper-body {
        .ag-cell {
          overflow: hidden;
          white-space: nowrap;
        }

        .ag-column-hover {
          background-color: var(--ag-row-hover-color);
        }

        .ag-row-hover .ag-column-hover {
          background-color: lightgray;
          border: 1px solid var(--wpp-grey-color-600) !important;
          color: var(--wpp-brand-color);
          font-weight: bold;
        }
      }
    }
  }
}
