.project-registry {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  margin-bottom: 1rem;

  .registry-filters {
    font-size: 0.75rem;
    padding: 0.5rem;
    border: 1px solid var(--wpp-grey-color-300);
    border-radius: 0.25rem;

    .wpp-input {
      display: flex;

      &::part(input) {
        font-size: 0.75rem;
      }
    }

    .wpp-datepicker {
      &::part(datepicker-container) {
        max-width: 7rem;
      }

      &::part(datepicker-input) {
        font-size: 0.75rem;
        padding-right: 0;
      }

      &::part(icon-cross) {
        display: none;
        margin: 0;
        padding: 0;
        width: 0;
      }
    }

    .wpp-single-select,
    .wpp-multiple-select {
      &::part(body) {
        font-size: 0.75rem;
      }

      &::part(options-list) {
        max-height: 12rem;
        font-size: 0.75rem;
      }

      &::part(placeholder) {
        font-size: 0.75rem;
      }
    }

    .wpp-list-item::part(label) {
      font-size: 0.75rem;
    }

    .label {
      font-weight: bold;
      text-align: end;
      padding-right: 0.5rem;
    }

    .row-1 {
      display: flex;

      .col-1 {
        display: block;

        &:nth-of-type(1) {
          flex: 4;
          max-width: 4rem;
        }

        &:nth-of-type(3),
        &:nth-of-type(5) {
          flex: 6;
          max-width: 6rem;
        }

        &:nth-of-type(2) {
          flex: 17;
          max-width: 17rem;

          .wpp-list-item.dataset {
            border-radius: 0.5rem;

            > p {
              color: white;
            }

            &:hover {
              > p {
                color: black;
              }
            }
          }
        }

        &:nth-of-type(4) {
          min-width: 15rem;
          flex: 15;

          .wpp-input::part(body) {
            min-width: 15rem;
            flex: 15;
          }
        }

        &:nth-of-type(6) {
          flex: 8;
          max-width: 8rem;

          .wpp-single-select::part(body) {
            max-width: 8rem;
            flex: 8;
          }
        }
      }

      &:nth-of-type(3) {
        .col-1 {
          &:nth-of-type(2),
          &:nth-of-type(4) {
            text-align: left;
            min-width: 7rem;
            max-width: 7rem;
          }

          &:nth-of-type(5) {
            flex: 1;
            max-width: 100%;
            text-align: right;
          }
        }
      }
    }
  }

  .registry-table {
    font-size: 0.75rem;
    margin-top: 0.5rem;
    border: 1px solid var(--wpp-grey-color-300);
    border-radius: 0.25rem;
    display: flex;
    flex-direction: column;
    padding-top: 0.5rem;
    flex: 1;
    height: 100%;
    overflow: auto;

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    > .loading {
      margin: auto;
    }

    .wpp-action-button.inline {
      border: 1px solid transparent;
      border-radius: 1rem;
      background-color: white;
      height: 1.25rem;

      &::part(overlay) {
        border-radius: 1rem;
      }

      &::part(button) {
        font-size: 0.75rem;
      }

      &.export {
        margin-right: 0.75rem;
      }

      &.export-single,
      &.extend,
      &.delete {
        width: 4rem;

        &::part(button) {
          width: 4rem;
          display: block;
          line-height: 0.625rem;
        }
      }

      &.export,
      &.export-single {
        border: 1px solid var(--wpp-dataviz-color-cat-neutral-6);

        &::part(button) {
          color: var(--wpp-dataviz-color-cat-neutral-6);
        }
      }

      &.extend {
        border: 1px solid var(--wpp-dataviz-color-cat-dark-8);

        &::part(button) {
          color: var(--wpp-dataviz-color-cat-dark-8);
        }
      }

      &.delete {
        border: 1px solid var(--wpp-dataviz-color-cat-dark-3);

        &::part(button) {
          color: var(--wpp-dataviz-color-cat-dark-3);
        }
      }

      &.export-all {
        color: var(--wpp-dataviz-color-cat-dark-3);
        position: relative;
        margin-left: 0.5rem;
        top: 0.125rem;

        &::part(button) {
          background-color: var(--wpp-dataviz-color-cat-dark-3);
          border-radius: 1rem;
          color: white;

          &:hover {
            background-color: var(--wpp-dataviz-color-seq-warning-600);
          }
        }
      }
    }

    > .title {
      text-align: left;
      margin: 0 0 0.5rem 0.5rem;
    }

    > .table-container {
      display: flex;
      flex-direction: column;
      flex: 1;
      width: calc(100% - 1rem);
      padding-left: 0.5rem;

      .status-section {
        display: flex;
        flex-direction: column;
        padding-right: 0.5rem;

        .wpp-accordion {
          background-color: white;
          border-radius: 0.5rem;
          display: flex;
          padding: 0 0 0.5rem 0.5rem;
          margin-bottom: 0.5rem;

          &.table-section[expanded] {
            background-color: var(--wpp-grey-color-100);
            border: 1px solid var(--wpp-grey-color-300);
          }

          &::part(content) {
            margin: 0;
          }

          &::part(section) {
            margin: 0.5rem 0 0 0;
            display: flex;
          }

          &::part(title-wrapper) {
            flex: 1;
          }

          &::part(header-wrapper) {
            flex: 1;
          }

          &.data-item {
            padding-bottom: 0;

            &::part(section) {
              margin: 0;
              display: flex;
            }

            > .loading {
              margin: 0 auto;

              > p {
                font-size: 0.625rem;
              }
            }
          }
        }

        &:last-child {
          > .wpp-accordion {
            margin-bottom: 0;
          }
        }

        .table-section-header,
        .table-item-header {
          display: flex;
          flex: 1;

          .table-section-header-info,
          .table-item-header-info {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 0.25rem;

            .wpp-typography {
              color: var(--wpp-dataviz-color-cat-dark-3);
              margin-right: 0.75rem;
            }
          }

          .entries {
            border: 1px solid var(--wpp-dataviz-color-cat-dark-10);
            border-radius: 1rem;
            background-color: white;
            color: var(--wpp-dataviz-color-cat-dark-10);
            font-weight: bold;
            height: 1.25rem;
            line-height: 1.25rem;
            padding: 0 0.5rem;
          }
        }

        .table-section-content,
        .table-item-header,
        .table-item-content {
          display: flex;
          flex: 1;
          flex-direction: column;
          margin-right: 1rem;

          .item-region,
          .team-member-region {
            border: 1px solid transparent;
            border-radius: 0.25rem;
            color: white;
            font-weight: bold;
            padding: 0 0.5rem;
          }

          .item-region {
            margin-right: 0.5rem;
          }
        }

        .table-section-content-header {
          margin-left: 2rem;
          margin-right: 0.5rem;
          font-weight: bold;
        }

        .table-section-content-header,
        .table-item-header,
        .wpp-action-button::part(button) {
          font-size: 0.625rem;

          @media only screen and (min-width: 90rem) {
            font-size: 0.75rem;
            .row-1 {
              .col-1 {
                // Project Name
                &:nth-of-type(2) {
                  min-width: 18rem;
                }

                // Datasets
                &:nth-of-type(4) {
                  width: 6%;
                }

                // Users
                &:nth-of-type(5) {
                  width: 5%;
                }

                // Submitted on
                &:nth-of-type(6) {
                  min-width: 4rem;
                }
              }
            }
          }
        }

        .wpp-action-button {
          &::part(button) {
            margin: 0 0.5rem;
            padding: 0;
          }
        }

        .table-item-header {
          margin-top: auto;

          .row-1 > .col-1 > .item-region {
            margin: 0 auto;
          }
        }

        .table-item-content {
          > .title {
            display: flex;
            flex: 1;

            > .wpp-typography {
              margin: 0 0.75rem 0 2rem;
              flex: 1;
              color: var(--wpp-dataviz-color-cat-dark-3);
            }

            > .buttons {
              display: flex;
              flex-direction: column;
              align-items: center;

              > .wpp-action-button:nth-of-type(1) {
                margin-bottom: 0.5rem;
              }
            }
          }

          > .row-1 {
            font-size: 0.75rem;
            margin: 0;

            .col-1 {
              &.label {
                font-weight: bold;
                text-align: right;
              }

              &:nth-of-type(1) {
                width: 13%;
              }

              &:nth-of-type(2) {
                padding-left: 1rem;
                text-align: left;
                width: 24%;

                &.single {
                  width: 85%;
                }
              }

              &:nth-of-type(3) {
                padding-left: 1rem;
                text-align: left;
                width: 30%;
              }

              &:nth-of-type(4) {
                padding-left: 1rem;
                text-align: left;
                width: 10%;
              }

              &:nth-of-type(5) {
                padding-left: 1rem;
                text-align: left;
                min-width: 10%;
                width: 10%;
              }
            }

            &:nth-of-type(2),
            &:nth-of-type(3),
            &:nth-of-type(4),
            &:nth-of-type(5),
            &:nth-of-type(6) {
              .col-1 {
                max-height: 1.5rem;

                &:nth-of-type(2) {
                  width: 23%;
                }

                &:nth-of-type(3) {
                  text-align: right;
                  width: 11%;
                }

                &:nth-of-type(4) {
                  width: 39%;

                  .wpp-action-button {
                    background-color: var(--wpp-brand-color);
                    border: 0 solid transparent;
                    border-radius: 0.5rem;

                    &::part(button) {
                      color: white;
                      text-align: left;
                      font-size: 0.75rem;
                    }
                  }
                }
              }
            }

            &:nth-of-type(2) {
              .col-1 {
                &:nth-of-type(5) {
                  text-align: right;
                  min-width: 3.75rem;
                  width: auto;
                  flex: 1;
                }
              }
            }
          }
        }

        .table-item-header,
        .table-section-content-header {
          .row-1 > .col-1:last-child {
            min-width: 3.5rem;
            text-align: right;
          }
        }

        .table-item-header {
          .row-1 {
            .col-1 {
              // ID
              &:nth-of-type(1) {
                font-size: 0.5rem;
                font-weight: bold;
              }
            }
          }
        }

        &.pending {
          .row-1 .col-1 {
            width: 12.5%;
          }

          .table-section-content-header,
          .table-item-header {
            .row-1 {
              .col-1 {
                // ID
                &:nth-of-type(1) {
                  text-align: center;
                  flex: 8;
                }

                // Agency Name
                &:nth-of-type(2) {
                  text-align: left;
                  flex: 25;
                }

                // Project Name
                &:nth-of-type(3) {
                  text-align: left;
                  flex: 25;
                  overflow: hidden;
                  white-space: break-spaces;
                  overflow-wrap: anywhere;
                }

                // Region
                &:nth-of-type(4) {
                  flex: 6;
                }

                // Datasets
                &:nth-of-type(5) {
                  flex: 7;
                }

                // Users
                &:nth-of-type(6) {
                  flex: 6;
                }

                // Submitted on
                &:nth-of-type(7) {
                  flex: 12;
                }

                // JIRA Link
                &:nth-of-type(8) {
                  flex: 10;
                }

                // (Button)
                &:nth-of-type(9) {
                  min-width: 5rem;
                  width: 5rem;
                }

                &.export {
                  text-align: right;
                }
              }
            }
          }
        }

        &.active {
          .row-1 .col-1 {
            width: 9%;
          }

          .table-section-content-header,
          .table-item-header {
            .row-1 {
              .col-1 {
                // ID
                &:nth-of-type(1) {
                  text-align: center;
                  flex: 8;
                }

                // Agency Name
                &:nth-of-type(2) {
                  text-align: left;
                  flex: 10;
                }

                // Project Name
                &:nth-of-type(3) {
                  text-align: left;
                  flex: 18;
                  overflow: hidden;
                  white-space: break-spaces;
                  overflow-wrap: anywhere;
                }

                // Region
                &:nth-of-type(4) {
                  min-width: 2.5rem;
                  flex: 4;
                }

                // Datasets
                &:nth-of-type(5) {
                  min-width: 3rem;
                  flex: 5;
                }

                // Users
                &:nth-of-type(6) {
                  min-width: 2.5rem;
                  flex: 3;
                }

                // Submitted on
                &:nth-of-type(7) {
                  flex: 6;
                }

                // Active Since
                &:nth-of-type(8) {
                  flex: 6;
                }

                // Review Date
                &:nth-of-type(9) {
                  flex: 6;
                }

                // JIRA Link
                &:nth-of-type(10) {
                  flex: 6;
                }

                // Namespace
                &:nth-of-type(11) {
                  flex: 5;
                }

                // (Button)
                &:nth-of-type(12) {
                  min-width: 5rem;
                  width: 5rem;
                }

                &.export {
                  text-align: right;
                }
              }
            }
          }
        }

        &.deleted {
          .row-1 .col-1 {
            width: 12%;
          }

          .table-section-content-header,
          .table-item-header {
            .row-1 {
              .col-1 {
                // ID
                &:nth-of-type(1) {
                  text-align: center;
                  flex: 8;
                }

                // Agency Name
                &:nth-of-type(2) {
                  text-align: left;
                  flex: 16;
                }

                // Project Name
                &:nth-of-type(3) {
                  text-align: left;
                  flex: 20;
                  overflow: hidden;
                  white-space: break-spaces;
                  overflow-wrap: anywhere;
                }

                // Region
                &:nth-of-type(4) {
                  flex: 5;
                }

                // Datasets
                &:nth-of-type(5) {
                  flex: 6;
                }

                // Users
                &:nth-of-type(6) {
                  flex: 5;
                }

                // Submitted on
                &:nth-of-type(7) {
                  flex: 7;
                }

                // Deleted On
                &:nth-of-type(8) {
                  flex: 7;
                }

                // JIRA Link
                &:nth-of-type(9) {
                  flex: 6;
                }

                // (Button)
                &:nth-of-type(10) {
                  min-width: 5rem;
                  width: 5rem;
                }

                &.export {
                  text-align: right;
                }
              }
            }
          }
        }
      }
    }
  }
}

.wpp-modal.project-registry-remove {
  &::part(header-wrapper) {
    margin-top: 1rem;
  }

  .modal-header {
    color: var(--wpp-danger-color-500);
    margin-top: 1rem;

    .wpp-icon {
      position: relative;
      top: 0.125rem;
    }
  }

  .modal-body {
    display: block;
    margin-top: 1rem;
  }

  .modal-actions {
    .wpp-button {
      margin-right: 1rem;
    }
  }
}
