.request-management {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 1rem;

  > .navigation {
    width: 50rem;
  }

  > .filter {
    display: flex;
    margin-bottom: 0.5rem;

    .wpp-label {
      margin: auto 0.5rem auto 0;
    }

    .wpp-single-select {
    }
  }

  > .content {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .request {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: auto;
      border: 1px solid var(--wpp-grey-color-300);

      .loading {
        margin: auto;
      }

      .empty-table {
        display: block;
        margin: auto;
      }

      .approval {
        flex: 1;
        margin-top: 0.5rem;
        min-height: 3rem;
        max-height: 3rem;
        height: 3rem;
        background-color: var(--wpp-grey-color-100);
        border-radius: 0.25rem;
        border: none;
        display: flex;
        width: 100%;
        text-align: start;
        align-items: center;
        cursor: pointer;

        &:first-child {
          margin-top: 0;
        }

        .wpp-icon {
          color: var(--wpp-brand-color);
        }

        .title {
          flex: 1;
          margin-left: 0.25rem;
          font-size: 1rem;
          color: black;
          font-weight: bold;
          display: flex;

          > .id,
          > .regions > .region {
            color: white;
            border: 1px solid transparent;
            border-radius: 1rem;
            padding: 0.25rem 0.5rem;
            margin-right: 0.5rem;
            text-align: center;
          }

          > .id {
            background-color: var(--wpp-grey-color-600);
            padding: 0 0.25rem;
            font-size: 0.625rem;
            margin-top: 0.375rem;
            height: 0.75rem;
            width: auto;
          }

          > .info {
            background-color: transparent;
            margin-top: 0.25rem;
            text-align: left;
            font-size: 1rem;
            line-height: 1.25rem;
            flex: 1;
          }

          > .regions {
            background-color: transparent;
            display: flex;

            > .region {
              color: white;
              font-size: 0.75rem;
              /* width: 2.5rem;
              line-height: 0.75rem;
              height: 0.75rem; */

              &.AMER {
                background-color: var(--wpp-dataviz-color-cat-dark-4);
              }

              &.APAC {
                background-color: var(--wpp-dataviz-color-cat-dark-6);
              }

              &.EMEA {
                background-color: var(--wpp-dataviz-color-cat-dark-8);
              }
            }
          }
        }

        &:hover {
          background-color: var(--wpp-primary-color-200);
        }

        &.expanded {
          background-color: var(--wpp-brand-color);

          .wpp-icon {
            color: white;
          }

          .title {
            color: white;
          }
        }
      }

      .approval-content {
        border: 2px solid var(--wpp-brand-color);
        /* width: calc(100% - 2.25rem); */
        display: block;
        padding: 1.25rem 1rem 1rem;
        margin-top: -4px;
        border-radius: 4px;

        .approval-success {
          .approval-form {
            font-size: 12px;

            .wpp-label {
              font-weight: bold;
            }

            .wpp-typography::part(inner) {
              font-size: 14px;
            }

            .wpp-input {
              &[disabled]:not([disabled='false'])::part(input) {
                border: none;
                background-color: transparent;
                padding: 0;
                color: var(--typography-color);
              }

              &::part(input) {
                font-size: 12px;
              }
            }

            .pending {
              .wpp-input {
                &[disabled]:not([disabled='false'])::part(input) {
                  color: var(--wpp-grey-color-800);
                }
              }
            }

            .wpp-checkbox {
              margin-top: 2px;
            }

            .wpp-single-select,
            .wpp-multiple-select {
              &::part(body) {
                font-size: 12px;
              }

              &::part(options-list) {
                max-height: 150px;
                font-size: 12px;
              }

              &::part(placeholder) {
                font-size: 12px;
              }
            }

            .wpp-list-item::part(label) {
              font-size: 12px;
            }

            .dataset-container {
              margin: 0;

              .dataset-item {
                text-align: left;

                &.header {
                  margin-right: 20px;
                }
              }
              .dataset-region {
                min-width: 50px;
              }
            }

            .team-region {
              min-width: 50px;
            }
            .team-role {
              min-width: 100px;
            }

            .team-container {
              margin: 0;
              .team-item {
                text-align: left;

                &.header {
                  margin-right: 20px;
                }
              }
            }

            .row-1 {
              .col-1 {
                flex: 1;
                text-align: start;

                &:nth-of-type(1) {
                  flex: 0;
                  min-width: 175px;
                }

                &:nth-of-type(2) {
                  flex: 0;
                  min-width: 200px;
                }
              }
            }

            section {
              border-top: 1px solid var(--wpp-brand-color);

              .col-1 {
                padding-right: 1rem;
              }

              &.agency {
                .col-1 {
                  &:nth-of-type(2),
                  &:nth-of-type(3) {
                    flex: 2;
                  }
                }
              }

              &.project {
                .col-1 {
                  &:nth-of-type(2),
                  &:nth-of-type(3),
                  &:nth-of-type(5) {
                    // flex: 0;
                    // min-width: 200px;
                  }
                }

                .row-1:nth-of-type(4) .col-1:nth-of-type(2) {
                  min-width: 480px;
                }
              }

              &.team {
                .col-1 {
                  &:nth-of-type(4),
                  &:nth-of-type(5) {
                    flex: 0;
                    min-width: 125px;
                  }

                  &:nth-of-type(6) {
                    flex: 0;
                    min-width: 25px;
                  }

                  .wpp-single-select::part(body) {
                    max-width: 125px;
                  }
                }
              }

              &.datasets {
                .error {
                  color: var(--wpp-warning-color-400);
                }

                .row-1 {
                  &:nth-of-type(1) {
                    margin-bottom: 1rem;
                  }
                }

                .row-1:not(:nth-of-type(1)) {
                  .col-1 {
                    &:nth-of-type(1),
                    &:nth-of-type(2),
                    &:nth-of-type(3) {
                      flex: 0;
                      min-width: 19rem;
                      max-width: 19rem;
                      // one item
                      &:first-child:nth-last-child(1),
                      &:first-child:nth-last-child(1) ~ .col-1,
                        // two items
                      &:first-child:nth-last-child(2),
                      &:first-child:nth-last-child(2) ~ .col-1 {
                        margin: 0 0 0 11rem;
                      }

                      // three items
                      &:first-child:nth-last-child(3),
                      &:first-child:nth-last-child(3) ~ .col-1 {
                        margin: 0 0.75rem 0 0;
                      }
                    }
                  }

                  .dataset-container {
                    display: block;

                    &:empty {
                      background-color: var(--wpp-grey-color-200);
                      height: auto;
                      border-radius: 4px;
                    }

                    .dataset {
                      display: flex;
                      margin: 0 0.5rem 0.5rem 0;

                      .wpp-action-button {
                        margin-left: -0.5rem;
                      }

                      .source {
                        .wpp-single-select {
                          min-width: 12.5rem;

                          &::part(body) {
                            width: 12rem;
                          }
                        }
                      }

                      .region {
                        .wpp-single-select {
                          min-width: 6rem;

                          &::part(body) {
                            width: 5.5rem;
                          }
                        }
                      }
                    }
                  }

                  .dataset-approval {
                    display: block;
                    margin-top: 0;
                    padding: 8px;

                    &:not(:has(.dataset-approval-item)) {
                      background-color: var(--wpp-grey-color-200);
                      height: auto;
                      border-radius: 4px;
                      margin-bottom: 0;
                    }

                    &-item {
                      display: flex;
                      width: 16rem;

                      .key {
                        display: inline;
                        width: 14rem;
                      }
                    }
                  }

                  .additional {
                    .ao-id,
                    .use-case {
                      display: flex;

                      .label {
                        width: 68px;
                      }
                    }

                    .use-case {
                      display: flex;

                      .label {
                        margin-top: 4px;
                      }

                      .textfield {
                        flex: 1;

                        .wpp-input {
                          width: auto;
                        }
                      }
                    }
                  }
                }
              }

              &.info {
                .row-1:nth-of-type(2) .col-1:nth-of-type(1) {
                  margin-top: -4px;
                }

                .row-1.comments {
                  .col-1 {
                    &:nth-of-type(1) {
                      width: 200px;

                      .wpp-label {
                        margin-left: auto;
                        width: 65px;
                      }
                    }

                    &:nth-of-type(2) {
                      flex: 1;
                    }
                  }
                }
              }
            }

            .buttons {
              padding-top: 1rem;

              .wpp-action-button::part(button) {
                margin: 4px 1rem;
              }
            }
          }
        }
      }
    }
  }
}
