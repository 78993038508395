.footer-wrapper {
  background: radial-gradient(
    circle,
    rgba(202, 184, 255, 1) 0%,
    rgba(71, 3, 155, 1) 35%,
    rgba(0, 0, 0, 1) 100%
  );
  border-top: 2px solid var(--wpp-brand-color);
  height: 2rem;

  .copy,
  .wpp-action-button::part(inner) {
    font-size: 0.75rem;
    line-height: 2rem;
    text-shadow: white 1px 0 10px;
  }
  .wpp-action-button {
    margin: 0;
    padding: 0;

    &::part(button) {
      color: black;
      font-weight: normal;
      margin: 0;
      padding: 0;
    }
  }
  .wpp-modal.about {
    --wpp-modal-vertical-position-minus-number: 0.5rem;

    h4 {
      margin-bottom: 0.25rem;
    }
    h5 {
      margin: 0;
      padding: 0;
    }
  }
}
