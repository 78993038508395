.header-wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: var(--wpp-grey-color-200);

  .header {
    background: radial-gradient(
      circle,
      rgba(202, 184, 255, 1) 0%,
      rgba(71, 3, 155, 1) 35%,
      rgba(0, 0, 0, 1) 100%
    );
    border-bottom: 2px solid var(--wpp-brand-color);
    height: 4.5rem;

    @media only screen and (min-height: 50rem) {
      height: 7.5rem;
    }

    .brand-logo {
      position: relative;
      height: 3rem;

      @media only screen and (min-height: 50rem) {
        height: 5rem;
      }
    }

    .title {
      display: block;
      text-shadow: white 0.0625rem 0 0.625rem;
      font-size: 0.75rem;

      @media only screen and (min-height: 50rem) {
        font-size: 1rem;
      }
    }
  }

  .user {
    color: var(--wpp-grey-color-400);
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-shadow: white 0.0625rem 0 0.625rem;
    font-size: 0.75rem;

    @media only screen and (min-height: 50rem) {
      font-size: 1rem;
    }
  }
}
