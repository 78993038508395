.Home {
  height: 100%;

  > .loading {
    margin: auto;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .content-wrapper {
    padding: 20px;
    flex: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
    height: 100%;

    .wpp-button {
      &.width-200 {
        margin: 0.25rem;

        &::part(button) {
          width: 200px !important;
        }
      }

      &.width-300 {
        margin: 0.25rem;

        &::part(button) {
          width: 300px !important;
        }
      }
    }

    .wpp-modal {
      &::part(content) {
        width: 400px;
      }

      &::part(header-wrapper) {
        padding: 0;
      }
    }

    .ag-root-wrapper-body {
      .ag-cell {
        overflow: hidden;
        white-space: nowrap;
      }
    }

    .margin-left-auto {
      margin-left: auto;
    }

    .row-1 {
      display: flex;
      margin: 0.5rem 0;

      .col-1,
      .col-2,
      .col-3 {
        height: 100%;
        margin: auto 0;
        line-height: 24px;
      }

      .col {
        &-1 {
          width: 20%;
        }

        &-2 {
          width: 40%;
        }

        &-3 {
          width: 60%;
        }
      }
    }

    .PredictHQ,
    .SafeGraph,
    .Zip5,
    .ADM {
      background-color: var(--wpp-dataviz-color-cat-neutral-10) !important;

      &.light {
        background-color: var(--wpp-dataviz-color-cat-light-10) !important;
      }

      &.dark {
        background-color: var(--wpp-dataviz-color-cat-dark-10) !important;
      }
    }

    .Other {
      background-color: var(--wpp-dataviz-color-cat-neutral-1) !important;

      &.light {
        background-color: var(--wpp-dataviz-color-cat-light-1) !important;
      }

      &.dark {
        background-color: var(--wpp-dataviz-color-cat-dark-1) !important;
      }
    }

    .AMER {
      background-color: var(--wpp-dataviz-color-cat-neutral-4) !important;

      &.light {
        background-color: var(--wpp-dataviz-color-cat-light-4) !important;
      }

      &.dark {
        background-color: var(--wpp-dataviz-color-cat-dark-4) !important;
      }
    }

    .APAC {
      background-color: var(--wpp-dataviz-color-cat-neutral-6) !important;

      &.light {
        background-color: var(--wpp-dataviz-color-cat-light-6) !important;
      }

      &.dark {
        background-color: var(--wpp-dataviz-color-cat-dark-6) !important;
      }
    }

    .EMEA {
      background-color: var(--wpp-dataviz-color-cat-neutral-8) !important;

      &.light {
        background-color: var(--wpp-dataviz-color-cat-light-8) !important;
      }

      &.dark {
        background-color: var(--wpp-dataviz-color-cat-dark-8) !important;
      }
    }

    .CPG {
      background-color: var(--wpp-dataviz-color-cat-neutral-9) !important;

      &.light {
        background-color: var(--wpp-dataviz-color-cat-light-9) !important;
      }

      &.dark {
        background-color: var(--wpp-dataviz-color-cat-dark-9) !important;
      }
    }

    .IDN {
      background-color: var(--wpp-dataviz-color-cat-neutral-2) !important;

      &.light {
        background-color: var(--wpp-dataviz-color-cat-light-2) !important;
      }

      &.dark {
        background-color: var(--wpp-dataviz-color-cat-dark-2) !important;
      }
    }

    .AO,
    .Audience {
      background-color: var(--wpp-dataviz-color-cat-neutral-7) !important;

      &.light {
        background-color: var(--wpp-dataviz-color-cat-light-7) !important;
      }

      &.dark {
        background-color: var(--wpp-dataviz-color-cat-dark-7) !important;
      }
    }

    .Census {
      background-color: var(--wpp-dataviz-color-cat-neutral-3) !important;

      &.light {
        background-color: var(--wpp-dataviz-color-cat-light-3) !important;
      }

      &.dark {
        background-color: var(--wpp-dataviz-color-cat-dark-3) !important;
      }
    }

    .DataSpine {
      background-color: var(--wpp-dataviz-color-cat-neutral-5) !important;

      &.light {
        background-color: var(--wpp-dataviz-color-cat-light-5) !important;
      }

      &.dark {
        background-color: var(--wpp-dataviz-color-cat-dark-5) !important;
      }
    }

    .dataset-container {
      display: flex;
      flex-direction: row;
      margin-top: 0;
      padding-top: 0;

      .dataset-item {
        border: 0 solid transparent;
        border-radius: 0.5rem;
        display: block;
        text-align: left;

        .source-header,
        .source-item {
          border: 1px solid transparent;
          border-radius: 0.25rem;
          display: block;
          padding: 0 0.5rem;
        }

        .source-header {
          display: none;
          color: white;
          font-weight: bold;
          width: 12rem;
        }

        .source-item {
          color: white;
          display: inline;
          margin-right: 0.25rem;

          &:after {
            content: '\a';
            white-space: pre;
          }
        }
      }

      &.multiple {
        .dataset-item {
          height: 9.25rem;
          margin-right: 0.5rem;
          text-align: center;

          .source-header,
          .source-item {
            border: 1px solid transparent;
            border-radius: 0.25rem;
            display: block;
            padding: 0 0.5rem;
          }

          .source-header {
            display: block;
          }

          .source-item {
            color: white;
            display: inline;
            margin: 0 auto;
          }
        }
      }
    }

    .highlight {
      color: var(--wpp-brand-color);
    }

    .bold {
      font-weight: bold;
    }

    .select-no-list-items-container {
      margin: 0.3rem 0;
    }

    .new {
      &::after {
        content: 'NEW';
        position: relative;
        top: -0.75rem;
        font-weight: bold;
        font-size: 0.5rem;
        color: var(--wpp-warning-color-500);
        text-shadow: 1px 1px 2px orange;
        height: 0;
      }
    }
  }
  .wpp-tabs {
    .wpp-tab.new::part(inner) {
      &::after {
        content: 'NEW';
        position: relative;
        top: -1rem;
        font-weight: bold;
        font-size: 0.5rem;
        color: var(--wpp-warning-color-500);
        text-shadow: 1px 1px 2px orange;
        height: 0;
      }
    }
  }

  .ag-root {
    scrollbar-color: var(--wpp-grey-color-400) transparent;
  }
}
