.content-wrapper.main {
  padding: 0;

  #main-page {
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    flex: 1;

    .basic-grid {
      flex: 1;
      display: flex;
      flex-direction: column;

      .ag-body-viewport {
        cursor: pointer;
      }

      > div {
        &:nth-of-type(1) {
          flex: 1;
        }
      }

      .grid-button {
        display: inline-flex;

        .wpp-action-button {
          &::part(button) {
            color: black;
            font-weight: normal;
            padding: 0;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      .grid-icon-button {
        display: flex;
        margin: 4px;

        .wpp-icon-button {
          &::part(wrapper) {
            --wpp-button-secondary-border-color: var(--wpp-grey-color-400);
          }
          &::part(inner) {
            --button-secondary-icon-color: var(--wpp-grey-color-400);
          }

          &:hover {
            &::part(wrapper) {
              --wpp-button-secondary-border-color: var(--wpp-brand-color);
            }
            &::part(inner) {
              --button-secondary-icon-color: var(--wpp-brand-color);
            }
          }
        }
      }

      .region {
        color: #fff;
        border-radius: 0.25rem;
        padding: 0.5rem 1.5rem;
        text-shadow: 1px 1px 2px #000;
      }

      .sources {
        display: flex;
        max-width: 100%;
        flex-wrap: wrap;

        .source {
          border-radius: 0.25rem;
          color: #fff;
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-top: 0.5rem;
          margin-right: 0.5rem;
          min-height: 1.75rem;
          /* max-width: 10rem; */
          min-width: 5rem;
          padding: 0 0.5rem;
          background-color: var(--wpp-grey-color-600);

          > span {
            color: #fff;
            display: block;
            font-size: 0.75rem;
            line-height: 0.75rem;
            font-weight: 700;
            margin: auto;
            padding: 0;
            text-shadow: 1px 1px 2px #000;

            &.gov {
              font-size: 0.5rem;
            }

            &:not(:only-child) {
              margin-top: 0.125rem;
            }
          }
        }
      }
    }

    .details-grid {
      display: flex;
      flex-direction: column;
      flex: 1;

      .details-overlay::part(button) {
        background-color: transparent;
        min-width: 20%;
        position: absolute;
        top: 8rem;
      }

      > div {
        flex: 1;
      }

      > span.error {
        display: flex;
        flex: 1;

        .wpp-button.notebook-error {
          margin: auto;
          cursor: pointer;
          border-radius: 4px;
          justify-content: center;
          flex: 1;

          &::part(inner) {
            span {
              display: block;
            }
          }

          &::part(button) {
            background-color: var(--wpp-dataviz-color-cat-neutral-5);
            text-shadow: 1px 1px 2px black;
          }
        }
      }

      > span.footer {
        display: flex;
        height: 4rem;

        > .wpp-button {
          margin: auto;
        }
      }

      > h2 {
        margin-bottom: 0.5rem;
        padding-bottom: 0.5rem;
      }

      > h4 {
        margin-top: 0;
        padding-top: 0;
      }

      .ag-header-cell-text {
        white-space: normal;
      }

      .ag-cell[col-id='0'],
      .ag-cell[col-id='2'] {
        display: flex;
      }

      .detail-status-button {
        /* flex: 1; */
        margin: auto;

        .show-on-hover {
          color: var(--wpp-primary-color-200);
        }

        &:hover {
          .show-on-hover {
            color: var(--wpp-brand-color);
          }
        }
      }

      .detail-connect-button::part(button) {
        background-color: transparent;
        font-size: 14px;
        font-weight: 500;
      }

      .detail-connect-button::part(overlay) {
        border-radius: 0px;
      }

      .detail-actions {
        flex: 1;
        display: flex;
        height: 100%;

        .wpp-icon-button {
          flex: 1;
          margin: auto;

          &::part(wrapper) {
            --wpp-button-secondary-border-color: transparent;
            --button-secondary-border-color-disabled: transparent;
          }
        }
      }
    }

    .content {
      display: flex;
      flex: 1;

      .loading {
        margin: auto;
      }
    }
  }
}
