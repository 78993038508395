.content-wrapper.request {
  padding: 0;

  > div {
    &.team-form,
    &.datasets-form,
    &.access-request-form {
      background-color: white;
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow: hidden;

      > .headline {
        display: block;
        margin: 1rem 0;
        text-align: center;

        * {
          color: var(--wpp-brand-color);
        }
      }

      > .buttons {
        width: 100%;
        background-color: white;
        border-top: 1px solid var(--wpp-grey-color-300);

        .wpp-action-button {
          margin-top: 8px;
        }
      }

      > .content {
        padding: 1rem;
        flex: 1;
        overflow: auto;

        > section {
          .col-1:nth-of-type(1) {
            > .label {
              padding-right: 2rem;

              &.help {
                padding-right: 8px;
              }
            }
          }

          > .row-1:nth-of-type(1) > .col-1:nth-of-type(1) .wpp-icon-help {
            margin-top: 6px;
          }

          .wpp-icon-help {
            position: relative;
            bottom: 6px;
            color: var(--wpp-brand-color);
          }

          .wpp-single-select {
            &::part(options-list) {
              max-height: 150px;
            }
          }

          &.centered {
            .row-1 .col-1:nth-of-type(1) {
              width: 40%;
            }
          }

          .section-label {
            display: block;
            text-align: center;
          }

          .label {
            display: block;
            font-weight: bold;
            padding-right: 1rem;
            text-align: end;
          }

          .info {
            color: var(--wpp-grey-color-900);
            display: block;
            font-size: 10px;
            line-height: 14px;
            text-align: start;
          }

          .wpp-single-select,
          .wpp-multiple-select,
          .wpp-input {
            margin-right: 0.5rem;
          }

          .wpp-single-select::part(single-select-input),
          .wpp-multiple-select::part(multiple-select-input),
          .wpp-input::part(input) {
            background-color: white;
          }

          .wpp-action-button {
            display: block;
          }
        }
      }
    }

    &.access-request {
      display: block;
      padding: 20px;
    }
  }
}
