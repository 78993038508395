.wrapper {
  box-sizing: border-box;
  border-radius: 8px;
}

.item + .item {
  margin-top: 32px;
}

.results {
  margin-top: 16px;
}

.mono {
  margin: 0;
  padding: 8px;
  overflow: hidden;
  white-space: pre-wrap;
  background-color: #faf9f5;
  border: 1px solid var(--wpp-grey-color-600);
  border-radius: 4px;
}

.selectedValues, .pill {
  width: 100%;
}