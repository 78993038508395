.quick-reports {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: left;
  min-height: calc(100% - 2rem);
  margin-top: 1rem;

  .wpp-single-select {
    min-width: 25rem;
    max-width: 25rem;

    &::part(body) {
      font-size: 0.75rem;
    }

    &::part(options-list) {
      max-height: 12rem;
      font-size: 0.75rem;
    }

    &::part(placeholder) {
      font-size: 0.75rem;
    }
  }

  .date-section {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0.5rem 0.5rem 0.5rem 0;

    > div:nth-child(2) {
      margin-left: 0.5rem;
    }
    .wpp-label {
      margin-right: 1rem;
      white-space: nowrap;
    }

    .flex-center-start {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .generateCostReport {
      margin-left: 1rem;
    }

    .wpp-datepicker {
      &::part(datepicker-container) {
        max-width: 8rem;
      }

      &::part(datepicker-input) {
        font-size: 0.75rem;
        padding-right: 0;
      }

      &::part(icon-cross) {
        display: none;
        margin: 0;
        padding: 0;
        width: 0;
      }

      &::part(datepicker) {
        transform: translate(-8rem, 0px);
      }
    }
  }

  > .timespan-selection-info-message {
    margin: 0;
  }

  > .error-message {
    margin: 2rem 0;
  }
  .option-to-content {
    display: block;
    width: 100%;
    height: 100%;
    font-family: monospace;
    font-size: 14px;
    line-height: 1.6rem;
    resize: none;
    box-sizing: border-box;
    outline: none;
    color: rgb(0, 0, 0);
    border: 1px solid rgb(51, 51, 51);
    background-color: rgb(255, 255, 255);
    overflow: auto;
  }
  > .report-type-selection-container,
  > .timespan-selection-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;

    > .wpp-typography {
      margin: auto 1rem auto 0;
    }
  }
  > .rendering-charts-section {
    position: relative;
    > .info-message {
      position: absolute;
      margin: 0 auto;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      > .wpp-typography {
        position: absolute;
        margin: 0 auto;
        left: 0;
        right: 0;
        top: 6rem;
        background: var(--wpp-grey-color-400);
        padding: 1rem;
        z-index: 1;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    > .charts-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;
      border-radius: 0.25rem;

      &.disable {
        opacity: 0.2;
        pointer-events: none;
      }

      > .single-chart-container {
        border: 1px solid var(--wpp-grey-color-500);
        border-radius: 0.5rem;
        display: flex;
        flex: 1;

        > .echarts-for-react {
          margin: auto;
          height: 25rem !important;
          width: 50rem;
        }

        > .loading {
          height: 25rem;
          width: 50rem;
          display: flex;
          flex: 1;
          flex-direction: column;
          justify-content: center;

          > .wpp-spinner {
            margin: 0 auto;
          }

          > p {
            margin: 0 auto;
          }
        }
      }
    }
  }
  > .export-container {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}
