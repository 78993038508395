.loginRedirect {
  display: flex;
  flex-direction: column;
  min-height: 720px;
  min-width: 1280px;
  text-align: center;
  height: 100%;

  > .loading {
    margin: auto;
  }
}
