:root {
  --ag-icon-font-family: 'agGridMaterial';
}
section.overview {
  display: flex;
  height: 100%;
  flex-direction: column;
  padding-top: 1rem;

  > section {
    &.overview-info {
      display: flex;
      flex-direction: row;
      margin: 0 auto 1rem;

      > .overview-item {
        display: flex;
        flex-direction: column;
        width: 10rem;

        .overview-value {
          color: var(--wpp-brand-color);
          font-size: 2rem;
        }
      }
    }

    &.overview-grid {
      display: flex;
      flex-direction: column;
      flex: 1;
      height: 100%;

      .ag-grid-asd {
        width: 100%;
        height: 100%;
      }
      .ag-root-wrapper-body {
        font-size: 0.75rem;

        .ag-cell {
          font-size: 0.75rem;

          .stat-cell {
            > .value {
              display: block;
            }
            > .stat {
              display: flex;

              > .value,
              > .total {
                display: block;
                position: relative;
                bottom: 1rem;
                height: 0.25rem;
              }

              > .value {
                background-color: var(--wpp-brand-color);
              }
              > .total {
                background-color: var(--wpp-primary-color-200);
              }
            }
          }
        }

        .ag-row-hover .ag-column-hover {
          .stat-cell .stat .total {
            background-color: var(--wpp-primary-color-100);
          }
        }
      }
    }
  }
}
