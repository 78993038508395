.error-page {
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: center;
  background-color: var(--wpp-grey-color-200);

  .header {
    background: radial-gradient(
      circle,
      rgba(202, 184, 255, 1) 0%,
      rgba(71, 3, 155, 1) 35%,
      rgba(0, 0, 0, 1) 100%
    );
    border-bottom: 2px solid var(--wpp-brand-color);
    height: 7.5rem;

    img {
      position: relative;
      height: 5rem;
    }

    span {
      display: block;
      text-shadow: white 1px 0 10px;
    }
  }

  .error {
    flex: 1;

    &-code {
      color: var(--wpp-primary-color-300);
      font-size: 5rem;
      font-weight: bold;
      margin: 1rem 0 0 0;
    }

    &-message {
      color: var(--wpp-primary-color-600);
      font-size: 0.75rem;
      margin-top: 0;
    }

    &-text {
      color: var(--wpp-brand-color);
      font-size: 1.5rem;
      font-weight: bold;
      margin-top: 4rem;
    }

    &-hint {
      font-size: 0.75rem;

      .wpp {
        color: var(--wpp-brand-color);
        font-weight: bold;
      }
    }
  }

  .footer {
    background: radial-gradient(
      circle,
      rgba(202, 184, 255, 1) 0%,
      rgba(71, 3, 155, 1) 35%,
      rgba(0, 0, 0, 1) 100%
    );
    border-top: 2px solid var(--wpp-brand-color);
    height: 2rem;

    .copy {
      font-size: 0.75rem;
      line-height: 2rem;
      text-shadow: white 1px 0 10px;
    }
  }
}
