.content-wrapper.dashboard {
  overflow: hidden;
  padding-bottom: 0;

  .dashboard {
    display: flex;
    flex-direction: row;
    flex: 1;
    max-height: 100%;

    > .label {
      &.main::part(inner) {
        font-size: 24px;
        line-height: 24px;
      }
    }

    > .left-bar {
      display: flex;
      flex-direction: column;

      > .wpp-typography {
        border-bottom: 1px solid var(--wpp-grey-color-300);
        margin-right: 1rem;
        padding-bottom: 1rem;
      }

      > .navigation {
        display: flex;
        flex-direction: column;
        flex: 1;
        min-width: 13rem;
        max-width: 16rem;
        margin-top: 1rem;
        margin-right: 1rem;

        .item {
          display: block;
          background-color: transparent;
          border: none;
          border-left: 4px solid transparent;
          height: 2rem;
          padding: 0 0 0 1rem;
          width: 100%;
          text-align: start;
          cursor: pointer;
          font-weight: bold;

          &:hover {
            border-left-color: var(--wpp-primary-color-200);
          }

          &:disabled {
            cursor: not-allowed;

            &:hover {
              border-left-color: var(--wpp-grey-color-200);
            }
          }

          &.selected {
            border-left-color: var(--wpp-brand-color);
            cursor: default;
            color: var(--wpp-brand-color);
          }
        }
      }
    }

    > .page {
      display: flex;
      flex: 1;
    }
  }
}
