.team-form {
  > .content {
    .demo-instruction {
      text-align: left;

      em {
        font-style: italic;
      }

      .brand-logo {
        height: 20px;
        position: relative;
        bottom: -4px;
      }

      .demo-referer-url {
        text-decoration: underline !important;
        color: -webkit-link;
      }
    }
    > section {
      .demo-default-fields {
        display: none;
      }
      .row-1 {
        &.team-row {
          .col-1 {
            .wpp-input {
              margin-left: auto;
            }
            &.has-input-field {
              margin-top: 0;
            }
          }
        }

        .col-1 {
          &:nth-of-type(2) {
            width: 40%;
          }

          .team-mate-container {
            margin: 0;
            padding: 0;
            text-align: left;

            .team-mate {
              display: block;

              &:not(:last-child) {
                margin-bottom: 0.5rem;
              }

              .item-content {
                background-color: var(--wpp-brand-color);
                border: 1px solid transparent;
                border-radius: 0.5rem;
                color: white;
                padding: 0.25rem;
              }
            }
          }
        }
      }
    }
  }
}
