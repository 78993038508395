section.advanced {
  display: flex;
  height: 100%;
  flex-direction: column;
  padding-top: 1rem;

  > .wpp-typography {
    margin-top: 1rem;
  }

  > section.top {
    display: flex;
    flex-direction: column;

    > section.filters {
      display: flex;
      flex-direction: column;
      max-width: 40rem;
      flex: 1;

      > section.basic-filter {
        display: flex;
        flex-direction: row;
        margin: 0;
        text-align: left;

        > * {
          margin: auto;
        }
        > span.label {
          width: 8rem;
        }
        > .wpp-single-select,
        > .wpp-input {
          flex: 1;
        }
      }
    }
    > section.info {
      display: block;
      margin: 0 0.5rem;
      text-align: left;
      flex: 1;

      > .label {
        font-weight: bold;
      }
    }
  }

  > section.advanced-grid {
    display: flex;
    flex-direction: column;
    flex: 1;

    .ag-root-wrapper-body {
      font-size: 0.75rem;

      .ag-header-cell-label {
        justify-content: center;
      }

      .ag-cell {
        font-size: 0.75rem;
      }
    }
  }
}
