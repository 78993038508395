.access-request-form {
  > .navigation {
    background-color: white;
    padding-bottom: 4px;
    display: block;
    width: 100%;
    border-bottom: 1px solid var(--wpp-grey-color-300);
  }

  > .content {
    > section {
      &.team-details,
      &.data-access-details {
        .align-center {
          margin: auto;
        }

        .section-label {
          text-align: start;
          margin-left: 60px;
        }

        .label {
          text-align: center;
        }
      }

      &.team-details {
        .row-1 {
          .col-1:nth-of-type(1) {
            flex: 1;
            min-width: 160px;
          }

          .col-1:nth-of-type(2) {
            width: 300px;
          }

          .col-1:nth-of-type(3) {
            width: 300px;
          }

          .col-1:nth-of-type(4),
          .col-1:nth-of-type(5) {
            width: 125px;

            .wpp-single-select::part(body) {
              width: 117px;
            }
          }

          .col-1:nth-of-type(6) {
            flex: 1;
            min-width: 160px;
          }
        }
      }

      &.data-access-details {
        .wpp-icon-help {
          bottom: 0;
        }

        .data-access-details-region {
          margin-bottom: 2rem;
          border: 1px solid var(--wpp-grey-color-300);
          border-radius: 1rem;

          &:last-child {
            margin-bottom: 0;
          }

          .section-label {
            color: var(--wpp-brand-color);
            margin: auto;
          }
        }

        .row-1 .col-1 .label {
          text-align: center;
        }

        .row-1 {
          .col-1:nth-of-type(1) {
            flex: 1;
          }

          .col-1:nth-of-type(2) {
            width: 20rem;

            .wpp-single-select::part(body) {
              width: 19rem;
            }
          }

          .col-1:nth-of-type(3) {
            width: 8rem;

            .wpp-single-select::part(body) {
              width: 7rem;
            }
          }

          .col-1:nth-of-type(4) {
            flex: 1;
            min-width: 180px;
          }

          &.three {
            .col-2.inline {
              display: flex;

              > * {
                margin: auto 0;
                flex: 1;

                &.label {
                  max-width: 6rem;
                }
              }
            }
            .col-1 {
              &:nth-of-type(1),
              &:nth-of-type(3) {
                flex: 1;
              }
            }
            .col-2 {
              width: 27.5rem;
              margin-right: 0.5rem;
              .checkbox {
                width: 2rem;
              }
            }
          }

          &.ao {
            .col-1 {
              &:nth-of-type(1) {
                flex: 1;
              }

              &:nth-of-type(2) {
                width: 136px;
                display: flex;

                .value {
                  margin-top: 10px;
                }
              }

              &:nth-of-type(3) {
                display: flex;
                flex: 2;

                .value {
                  flex: 1;
                }
              }

              &:nth-of-type(4) {
                flex: 1;
              }

              .label {
                line-height: 40px;
              }
            }
          }
        }
      }

      &.summary-and-submit {
        padding: 0;

        .label,
        span.col-1 {
          text-align: left;
        }

        .section-label {
          color: var(--wpp-brand-color);
        }

        .step {
          margin: 1rem;
          border: 1px solid var(--wpp-grey-color-300);
          border-radius: 1rem;
        }

        .row-1 {
          .col-1 {
            .label {
              text-align: left;
            }

            &:nth-of-type(1) {
              position: relative;
              top: 1rem;
              width: 200px;
            }

            &:nth-of-type(2) {
              width: 100px;
            }

            &:nth-of-type(3) {
              text-align: left;
              min-width: 250px;
            }

            &:nth-of-type(4) {
              flex: 1;
              max-width: 300px;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }

            &:nth-of-type(5) {
              text-align: left;
              width: 200px;
            }

            &:nth-of-type(6) {
              width: 175px;
            }

            &:nth-of-type(7) {
              text-align: left;
              min-width: 150px;
              flex: 1;
            }
          }

          &.user-label {
            .col-1 {
              &:nth-of-type(1) {
                position: relative;
                top: 1rem;
                width: 200px;
              }

              &:nth-of-type(5) {
                flex: 0;
                min-width: 200px;
              }
            }
          }

          &.user-value {
            .col-1 {
              &:nth-of-type(1) {
                position: relative;
                top: 1rem;
                width: 200px;
              }

              &:nth-of-type(3) {
                flex: 0 0;
                min-width: 250px;
              }

              &:nth-of-type(4) {
                text-align: left;
                margin: 0;
                flex: 1;
              }

              &:nth-of-type(5) {
                flex: 0;
                min-width: 200px;
              }
            }
          }

          &.team-label {
            .col-1 {
              &:nth-of-type(1) {
                position: relative;
                top: 1rem;
                width: 200px;
              }

              &:nth-of-type(3) {
                flex: 0 0;
                min-width: 250px;
              }

              &:nth-of-type(5) {
                max-width: 125px;
              }

              &:nth-of-type(6) {
                max-width: 125px;
              }

              > .label {
                text-align: left;
              }
            }
          }

          &.team-member {
            .col-1 {
              text-align: left;

              &:nth-of-type(3) {
                flex: 0 0;
                min-width: 250px;
              }

              &:nth-of-type(5) {
                max-width: 125px;
              }

              &:nth-of-type(6) {
                max-width: 125px;
              }
            }
          }

          &.datasets {
            .col-1:nth-of-type(1) {
              position: relative;
              top: 1.5rem;
              width: 200px;
            }

            .col-2 {
              flex: 1;
              max-width: 300px;

              > .label {
                text-align: center;
                padding-right: 0;
              }

              > .dataset-item {
                display: flex;
                justify-content: center;
                align-items: flex-start;
                margin-bottom: 0.5rem;
                font-size: 0.75rem;

                > .source,
                > .dataset {
                  border: 1px solid var(--wpp-grey-color-300);
                  padding: 0.25rem;
                  margin: 0 0.25rem;
                  min-width: 2.5rem;
                }

                > .source {
                  width: 14rem;
                }

                .wpp-tooltip.source {
                  display: block;
                  background-color: var(--wpp-dataviz-color-cat-neutral-7);
                  color: white;
                  cursor: pointer;

                  &::part(anchor) {
                    display: flex;
                  }
                  &::part(inner) {
                    display: block;
                    margin: auto;
                    min-width: 5.5rem;
                  }
                }
              }
            }
          }
        }

        .project-details {
          > .row-1 > .col-1 {
            &:nth-of-type(1) {
              position: relative;
              top: 1rem;
              width: 200px;
            }

            &:nth-of-type(3) {
              min-width: 175px;
              flex: 0;
            }

            &:nth-of-type(4) {
              max-width: 375px;
              flex: 1;
            }

            &:nth-of-type(5) {
              text-align: left;
              min-width: 175px;
              flex: 0;
            }

            &:nth-of-type(6) {
              text-align: left;
              min-width: 150px;
              flex: 0;
            }
          }
        }

        .disclaimer-field,
        .disclaimer-toggle {
          .col-1 {
            &:nth-of-type(1) {
              text-align: left;
              display: flex;
              justify-content: center;
              flex: 1;

              .wpp-toggle {
                margin: auto 1rem;
              }

              span.disclaimer-text {
                width: 39rem;
              }
            }
          }
        }
        .disclaimer-toggle {
          border: 1px solid var(--wpp-danger-color-400);
          border-radius: 0.25rem;
          margin: auto;
          padding: 0.25rem;
          width: 9.5rem;

          &.no-border {
            border-color: var(--wpp-success-color-400);
          }

          .col-1 {
            top: 0 !important;

            span {
              margin-right: 1rem;
              width: 6rem;
            }
          }
        }

        .row-1:nth-of-type(1) .col-1 {
          top: 0;
        }
      }
    }
  }
}

.projectDetails-tooltip-wrapper {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 6px 8px;
  border-radius: 6px;
  overflow-wrap: break-word;
  hyphens: auto;
  color: var(--wpp-grey-color-000);
  background-color: var(--wpp-dataviz-color-seq-grey-600);

  .wpp-typography {
    color: var(--wpp-grey-color-000);
  }
}

.reason-for-request {
  .wpp-tooltip::part(tooltip) {
    display: none;
  }
}

.disclaimer-url {
  text-decoration: underline !important;
  color: -webkit-link;
}
