.key-platform-metrics {
  > .section-container {
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media only screen and (max-width: 992px) and (min-width: 768px) {
      .section {
        flex: 50%;
      }
    }
    @media (max-width: 768px) {
      .section {
        flex: 100%;
      }
    }

    > .section {
      > .title {
        white-space: nowrap;
      }
      .value {
        border-radius: var(--wpp-border-radius-xs);
        margin: 10px 10px 0 10px;
        border: 2px solid;
        padding: 0.3rem;
        color: var(--wpp-primary-color-400);
        border-color: var(--wpp-primary-color-300);
        > .wpp-typography {
          color: var(--wpp-primary-color-400);
        }
      }
    }
  }
  > .export-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}
