.metrics-and-reports {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  margin-left: 1rem;

  > .page {
    overflow: auto;
    height: 100%;
  }
}
